.gm-style .gm-style-iw-c {
    padding-right: 10px !important;
    padding-bottom: 0px !important;
    max-width: 500px !important;
    max-height: 326px !important;
    min-width: 0px !important;
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.gm-style-iw-ch {
    width: 0 !important;
    height: 0 !important;
    background-color: aqua !important;
    display: none !important;
}

.gm-style-iw-chr>button{
    display: none !important;
}